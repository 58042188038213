$(function () {
  $('[data-toggle="popover"]').popover()
})

$( document ).ready(function() {
    console.log( "ready!" );
    InitWaypointAnimations();
	$('.example-popover').popover({
		container: 'body'
	})
});


